* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.contact-wrapper {
    background-color: #3e265f;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.contact-wrapper .logo {
    font-size: 24px;
    font-weight: bolder;
    display: flex;
    align-items: center;
}

.contact-wrapper .logo i {
    color: gold;
    margin-right: 10px;
}

.contact-wrapper .contact {
    display: flex;
    font-size: 14px;
    align-items: center;
}

.contact-wrapper .contact .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
}

.contact-wrapper .contact .info .info-details {
    margin-left: 10px;
}

.icon-wrapper {
    width: 40px;
    height: 40px;
    background-color: rgb(45, 45, 137);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-wrapper i {
    color: yellow;
}
.footer-wrapper {
    background-color: #3e265f;
    padding: 20px;
    color: white;
    display: none;
}

.footer-wrapper .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-wrapper .contact .info {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .contact-wrapper {
        flex-direction: column;
        height: auto;
        padding: 10px 20px;
    }

    .contact-wrapper .logo {
        margin-bottom: -60px;
    }

    .contact-wrapper .contact {
        display: none; 
    }

    .footer-wrapper {
        display: flex; 
        flex-direction: column;
        align-items: center;
    }
}
