.order-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .order-list h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .filter-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .filter-buttons button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
  }
  
  .filter-buttons button:hover {
    background-color: #0056b3;
  }
  
  .orders {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .order {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .order h2 {
    margin-top: 0;
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    .order-list {
      padding: 10px;
    }
  
    .filter-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .filter-buttons button {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  @media (max-width: 480px) {
    .order-list {
      padding: 5px;
    }
  
    .filter-buttons button {
      padding: 8px 15px;
    }
  }
  