.change-password-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .change-password-page h1 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .change-password-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .change-password-page form div {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .change-password-page label {
    display: block;
    margin-bottom: 5px;
    font-size: 1em;
    color: #555;
    text-align: left;
  }
  
  .change-password-page input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    box-sizing: border-box;
  }
  
  .change-password-page button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .change-password-page button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .change-password-page {
      padding: 15px;
    }
  
    .change-password-page h1 {
      font-size: 1.8em;
    }
  
    .change-password-page form div {
      margin-bottom: 10px;
    }
  
    .change-password-page label {
      font-size: 0.9em;
    }
  
    .change-password-page input {
      padding: 8px;
      font-size: 0.9em;
    }
  
    .change-password-page button {
      width: 100%;
      padding: 10px;
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .change-password-page {
      padding: 10px;
    }
  
    .change-password-page h1 {
      font-size: 1.5em;
    }
  
    .change-password-page form div {
      margin-bottom: 8px;
    }
  
    .change-password-page label {
      font-size: 0.8em;
    }
  
    .change-password-page input {
      padding: 6px;
      font-size: 0.8em;
    }
  
    .change-password-page button {
      width: 100%;
      padding: 8px;
      font-size: 0.9em;
    }
  }
  