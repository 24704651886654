body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

html {
    overflow: scroll;
  
}  

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9vh;
    background-color: rgb(0, 137, 192);
    background: linear-gradient(to right, rgb(75, 75, 155) 70%, rgba(40, 40, 124, 0.2) 100%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.hamburger-menu {
    display: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-left: 1rem;
}

.navbar-links, .navbar-links-right {
    display: flex;
    gap: 1rem;
    margin-left: 10%;
    margin-right: 10%;
}

.navbar-links a, .navbar-links-right a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.navbar-links a:hover, .navbar-links-right a:hover {
    text-decoration: underline;
}

.navbar-links-right a {
    display: flex;
    align-items: center;
    gap: 0.9rem;
}

.request-quote {
    width: 100%;
    height: 50%;
    padding: 10px;
    background-color: white;
    color: rgb(6, 6, 64);
}

.dropdown {
    position: relative;
}

.dropdown-link {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgb(0, 137, 192);
    min-width: 100px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: rgba(40, 40, 124, 0.5);
}

.dropdown:hover .dropdown-content {
    display: flex;
}

.social-media {
    display: flex;
    gap: 0.5rem;
}
.navbar-links .logout-button, .navbar-links-right .logout-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    font: inherit;
    outline: inherit;
    text-decoration: none;
    color: red;
}


@media (max-width: 992px) {
    .navbar-links {
        margin-left: 5%;
        margin-right: 5%;
    }

    .navbar-links-right {
        margin-left: 5%;
        margin-right: 5%;
    }

    .request-quote {
        padding: 5px;
    }
}

@media (max-width: 768px) {
    .navbar-links {
        display: none;
        position: absolute;
        top: 9vh;
        left: 0;
        width: 100%;
        background-color: rgb(0, 137, 192);
        flex-direction: column;
        align-items: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
        animation: slideDown 1s forwards;
    }

    .navbar-links.open {
        display: flex;
    }

    .hamburger-menu {
        display: block;
        margin-left: 1rem;
    }

    .navbar {
        justify-content: space-between;
    }

    .navbar-links-right {
        flex-direction: row;
        align-items: flex-start;
        margin-right: 20%;
    }

    .navbar-links-right a  {
        margin-top: 10px;
    }

    .logout-button{
        margin-top: 10px;
    }

    .request-quote {
        height: 35px;
        width: 80px;
        margin-left: 1rem;
    }

    .request-quote p {
        font-size: 12px;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-top: -5px;
    }

    .social-media {
        display: none;
    }
}

@keyframes slideDown {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(-5%);
    }
}