body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: scroll;
}

.registration-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.registration-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #00aaff;
  outline: none;
}

.signup_btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #00aaff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup_btn:hover {
  background-color: #007acc;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .registration-container {
    max-width: 300px;
  }

  .registration-container h2 {
    font-size: 22px;
  }

  .form-group input {
    padding: 9px;
    font-size: 15px;
  }

  .signup_btn {
    padding: 9px;
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .registration-container {
    padding: 15px;
    margin: 20px auto;
  }

  .registration-container h2 {
    font-size: 20px;
  }

  .form-group input {
    padding: 8px;
    font-size: 14px;
  }

  .signup_btn {
    padding: 8px;
    font-size: 14px;
  }
}