.profile-page {
  max-width: 900px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

.profile-page h1 {
  text-align: center;
}

.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.profile-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 30px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-details {
  flex: 1;
  margin-top: 30px;
  max-width: 600px;
}

.profile-details p {
  margin-bottom: 10px;
  font-size: 16px;
}

.profile-details input[type='text'],
.profile-details input[type='tel'],
.profile-details input[type='date'],
.profile-details input[type='email'],
.profile-details textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.profile-details input[type='text']:focus,
.profile-details input[type='tel']:focus,
.profile-details input[type='date']:focus,
.profile-details textarea:focus {
  outline: none;
  border-color: #007bff;
}

.profile-details textarea {
  resize: vertical;
  height: 100px;
}

.profile-actions {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.save-button,
.profile-action,
.logout-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  box-sizing: border-box;
  text-align: center;
}

.save-button,
.profile-action {
  background-color: #007bff;
}

.save-button:hover,
.profile-action:hover {
  background-color: #0056b3;
}

.logout-button {
  background-color: #dc3545; 
}
@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    margin-bottom: 20px;
  }

  .profile-details {
    max-width: 100%;
  }

  .save-button,
  .profile-action,
  .logout-button {
    width: 100%;
    margin-bottom: 10px; 
  }
}

@media (max-width: 480px) {
  .profile-details p {
    font-size: 14px;
  }

  .profile-details input[type='text'],
  .profile-details input[type='tel'],
  .profile-details input[type='date'],
  .profile-details input[type='email'],
  .profile-details textarea {
    font-size: 14px;
  }

  .save-button,
  .profile-action,
  .logout-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
