body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.homepage {
    display: flex;
    color: white;
    font-size: 24px;
    overflow: hidden;
    position: relative;
    height: 90vh;
}

.homepage::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../assets/images/homebackgroundcargo.jpg');
    background-size: cover;
    background-position: center;
    opacity: 1.5; 
    z-index: -1; 
    height: 100vh;
}

.home_contents {
    margin-top: 10%;
    margin-left: 10%;
}

.home_contents h2 {
    font-size: 35px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.home_contents p {
    font-size: 17px;
    margin-top: 10px;
}

.home_btn {
    padding: 15px;
    width: 40%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: yellow;
}
.homepage_contents{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 30px;
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
}
.what_wedo{
    background-color: rgb(200, 241, 123);
    padding: 5px;
    margin-bottom: 10px;
    width: 75px;
}
.what_wedo h5{
    font-size: 10px;
}
.col_1 h3{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: blue;
} 
.sea_services{
    display: flex;
}
.air_services{
    display: flex;
}
.warehouse_services{
    display: flex;
}
.local_services{
    display: flex;
}
.sea_contents{
    margin-left: 20px;
}
.warehouse_contents{
    margin-left: 20px;
}
.warehouse_contents h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: blue;
}
.local_contents h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: blue;
}
.local_contents{
    margin-left: 20px;
}
.air_contents{
    margin-left: 20px;
}
.sea_contents h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: blue;
}
.air_contents h4{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: blue;
}
.sea_contents p{
    margin-top: 10px;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    margin-bottom: 10px;
}
.air_contents p{
    margin-top: 10px;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    margin-bottom: 10px;
}
.warehouse_contents p{
    margin-top: 10px;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    margin-bottom: 10px;
}
.local_contents p{
    margin-top: 10px;
    font-size: 13px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    margin-bottom: 10p
}
.section-2 .card{
    transform: translate(10%, 35%);
    width:80vw;
    padding:40px;
    position: relative;
    top:-200px;
    background-color: white;
}

 .section-2 .image-section{
    width:100vw;
    height:60vh;
    background-color: red;
    background-image: url('../../assets/images/homebackgroundcargo.jpg');
    
    background-position: center center;
    background-repeat:repeat;
}
.section-2 .card .why-us{
    display: flex;
}
.section-2{
    height: 60vh;
}
.mini-image{
    width: 100%;
    
    background-position:center left; 
} 
.mini-image .plane-img{
    width:60vw;
    height:30vh;
    object-fit:cover;
    object-position:100% 70%;
}

@media (max-width: 768px) {
    .homepage {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .home_contents {
        margin: 20% 5%;
        text-align: center;
    }

    .home_contents h2 {
        font-size: 6vw;
    }

    .home_contents p {
        font-size: 3.5vw;
    }

    .home_btn {
        font-size: 4vw;
        width: auto;
    }
    .homepage_contents {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .col_1 {
        text-align: center;
        margin-bottom: 20px;
    }

    .col_1 h3 {
        font-size: 6vw;
        text-align: center;
    }

    .what_wedo {
        margin-bottom: 10px;
    }

    .col_2,
    .col_3 {
        margin-top: 30px;
    }

    .sea_services,
    .air_services,
    .warehouse_services,
    .local_services {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }

    .sea_services .sea_img,
    .air_services .sea_img,
    .warehouse_services .sea_img,
    .local_services .sea_img {
        margin-bottom: 10px;
    }

    .sea_contents,
    .air_contents,
    .warehouse_contents,
    .local_contents {
        text-align: center;
        margin-left: 0;
    }

    .sea_contents h4,
    .air_contents h4,
    .warehouse_contents h4,
    .local_contents h4 {
        font-size: 5vw;
        text-align: center;
    }

    .sea_contents p,
    .air_contents p,
    .warehouse_contents p,
    .local_contents p {
        font-size: 3vw;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .home_contents {
        margin: 20% 5%;
    }

    .home_contents h2 {
        font-size: 8vw;
    }

    .home_contents p {
        font-size: 4vw;
    }

    .home_btn {
        font-size: 5vw;
        width: auto;
    }
    .col_1 h3 {
        font-size: 8vw;
    }

    .sea_contents h4,
    .air_contents h4,
    .warehouse_contents h4,
    .local_contents h4 {
        font-size: 6vw;
    }
}