.cargo-page {
  margin-right: 10%;
  margin-left: 10%;
}
.add-cargo-form, .user-cargos {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-cargo-form h2, .user-cargos h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}
.add-cargo-form input, 
.add-cargo-form select, 
.add-cargo-form textarea, 
.user-cargos input, 
.user-cargos select, 
.user-cargos textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}
.add-cargo-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-cargo-form button:hover {
  background-color: #0056b3;
}
.add-cargo-form input:focus, 
.add-cargo-form select:focus, 
.add-cargo-form textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.cargo-item {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cargo-item img {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cargo-item h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.cargo-item p {
  margin-bottom: 5px;
  color: #555;
}

@media (min-width: 600px) {
  .cargo-item {
    flex-direction: row;
    align-items: flex-start;
  }

  .cargo-item img {
    width: 150px;
    height: 100px;
    margin-right: 15px;
  }

  .cargo-item h3 {
    font-size: 1.1rem;
  }

  .cargo-item p {
    font-size: 0.9rem;
  }
}

@media (min-width: 1024px) {
  .cargo-item {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }

  .cargo-item img {
    width: 200px;
    height: 150px;
  }

  .cargo-item h3 {
    font-size: 1.3rem;
  }

  .cargo-item p {
    font-size: 1rem;
  }
}
