body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
  }

.footer-wrapper {
    background-color: #3e265f;
    padding: 20px;
    color: white;
    display: none;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); 
}

.footer-wrapper .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-wrapper .contact .info {
    margin-bottom: 10px;
}

.footer-wrapper .social-media {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.footer-wrapper .social-media a {
    color: white;
    font-size: 20px;
}

@media (max-width: 600px) {
    .footer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: static;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .contact-wrapper {
        padding-bottom: 80px; 
    }
}